.skip-link {
  position: absolute;
  top: 0.25em;
  left: 1em;
  z-index: 999;
  margin: 0;
}
.skip-link li {
  display: inline;
  white-space: nowrap;
  margin-right: 0.25em;
}
.skip-link li a {
  position: absolute;
  left: -99999rem;
  width: 300px;
  font-size: 13px;
}
.skip-link li a:focus {
  left: 0;
  text-align: center;
  color: white;
  background-color: var(--color-brand-900);
  border-radius: 16px;
  padding-top: 7px;
  padding-bottom: 7px;
}
.skip-link li a.connected-only {
  display: none;
}
@media (max-width: 767px) {
  .skip-link {
    display: none;
  }
}